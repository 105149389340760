function softslider() {
	if ($('#softmenu').length > 0) {
		$('#softmenu .left').click(function(){
			var e =$('#softmenu .holder a').first();
			e.css({'margin-right':0,'opacity':0,'margin-left':'-'+e.outerWidth()+'px'}).animate({'overflow':'hidden'},500,function(){
				e.detach();
				e.css({'opacity':1,'margin-left':0,'margin-right':20});
				$('#softmenu .holder').append(e);
			});
		});
		
		$('#softmenu .right').click(function(){
			var e =$('#softmenu .holder a').last();
			e.css({'opacity':0,'margin-right':0,'margin-left':'-'+e.outerWidth()+'px'}).detach();
			$('#softmenu .holder').prepend(e);
			e.css({'opacity':1});
			e.animate({'margin-left':0,'margin-right':20},'fast');
		});
	}
}

function mSlider(){

		if ($("#mSlider").size()){
	
			$("#mSlider").owlCarousel({
	
		      pagination: false,
	
		      navigation: true,
	
		      slideSpeed : 300,
	
		      paginationSpeed : 400,
	
		      singleItem:true,
	
		      autoHeight: true,
		      
		      responsiveRefreshRate : 0
	
		  });
	
		}

	}

// Переключатель версий сайтов с "мобильной" на "полную"
function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i=0; i<ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1);
        if (c.indexOf(name) == 0) return c.substring(name.length,c.length);
    }
    return "";
}

function site_versions() {
	if ($('#site_version').length > 0) {
		$('#site_version').click(function(){
				document.cookie="site_version="+$(this).attr('data-fastbtn_mark');
				document.location.href=location.href;
			return false;	
		});
	}
}

function navbar_form(f) {
	var e = f.find('#w2.form-control');
	var s = f.find('.btn.btn-default');
	if (e.hasClass('active')) {
		e.removeClass('active');
		s.attr('disabled','disabled');
	} else {
		s.removeAttr('disabled');
		e.addClass('active').focus();
	}
}

function rating() {
	if ($('.starrate_holder').length > 0) {
		var i = $('.starrate_holder').attr('data-rate');
		$('.starrate_holder .starrate_element:nth-child('+i+')').prevAll().addClass('checked');
		$('.starrate_holder .starrate_element:nth-child('+i+')').addClass('checked');
		
		$('.starrate_holder a.starrate_element').hover(function(){
			$(this).prevAll().addClass('checked');
			$(this).addClass('checked');
			$(this).nextAll().removeClass('checked');
		},function(){
			i = $('.starrate_holder').attr('data-rate');
			i++;
			$(this).parent('.starrate_holder').find('.checked').removeClass('checked');
			$(this).parent('.starrate_holder').find('.starrate_element:nth-child('+i+')').prevAll().addClass('checked');
			$(this).parent('.starrate_holder').find('.starrate_element:nth-child('+i+')').addClass('checked');
		});
		
		$('.starrate_holder a.starrate_element').click(function(){
			$('.starrate_holder').attr('data-rate',$(this).html());
			i = $('.starrate_holder').attr('data-rate');
			$(this).parent('.starrate_holder').find('.checked').removeClass('checked');
			$(this).prevAll().addClass('checked');
			$(this).addClass('checked');
		});
		
		$('.starrate_submit').click(function(){
			$(this).closest('.starrate_holder').html('<div style="color:#d64638;line-height:34px;margin:0 auto 15px;">Спасибо. Ваш голос учтен!</div>');
		});
	}
}

$(document).ready(function() {
	softslider();
	site_versions();
	rating();
	$("img.lazy").lazyload();
	if ($('.navbar-form').length > 0) {
		$('.navbar-form').hover(function(){
				navbar_form($('.navbar-form'));
		},function(){
			navbar_form($('.navbar-form'));
		});
	}
			if (window.location.pathname == "/")
				$(".row.btn-partner__wrapper > a").attr("href", "/tinyurl/5caae416ea37345");
				$(".row.btn-partner__wrapper > a > img").attr("src", "/img/800eur_casino_bonus_728x90_RU.jpg");
				$(".row.btn-partner__wrapper > a > img").css("display", "inline-block");
				
			$(".menu_burger").click(function() {
				if ($("#recommended18").css("display") == "none")
					$("#recommended18").slideDown(500);
				else
					$("#recommended18").slideUp(500);
			});
			
			jQuery(window).load(function () {
				jQuery('.last-news__item-img img').one('load', function(){
				    var source = $('.last-news .last-news__list'),
				        target = $('.last-news__item', source);
				    sameBlockSize(source, target);
				}).each(function(){
				    if(this.complete)
				        $(this).load();
				});
				jQuery('.last-news__item-img img').one('load', function(){
				    var source = $('.last-facts .last-news__list'),
				        target = $('.last-news__item', source);
				    sameBlockSize(source, target);
				}).each(function(){
				    if(this.complete)
				        $(this).load();
				});
			});
			
		//totop.init();
		//feedbackForm.init();
			
		mSlider();
		
	/*if ($('#game_content').length > 0) {
		if ($('#red_banners a').length > 0) {
			var l = $('#red_banners a').attr('href');
		} else var l = '/go/money';
		$('#game_content img').each(function(){
			if($(this).parent('a').length > 0) {
				$(this).parent('a').attr('href',l);	
			} else {
				$(this).wrap('<a href="'+l+'" rel="nofollow"></a>');
			}
		});
	}*/

    //insert iframe
    $('#iframe-background').on('click', function() {

        $('.btn-startgame').click();

        return false;
    });

});
		