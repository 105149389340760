function vmiddle(c) {
	$(c).each(function() {
		var c = $(this),
			n = c.height(),
			a = c.find("img").height(),
			n = n / 2 - a / 2;
		c.find("img").css("margin-top", n)
	})
} 
function vmiddleObject(c, h) {
	$(c).each(function() {
		var c = $(this),
			a = c.height(),
			d = c.find(h).height(),
			a = a / 2 - d / 2;
		c.find(h).css("margin-top", a)
	})
}

function showTooltip() {
	$(".game-iframe__btn").tooltip({
		trigger: "hover",
		placement: "top"
	})
}

sliderFn = function() {
	window.sliderBx = $(".main-carousel .carousel-list").bxSlider({
		minSlides: 3,
		maxSlides: 3,
		moveSlides: 3,
		infiniteLoop: !1,
		hideControlOnEnd: !0,
		slideWidth: 319
	});
	$(".slider .slider-list").bxSlider({
		infiniteLoop: !1,
		hideControlOnEnd: !0
	});
	var c = function() {
			$(".slider-next > a").removeClass("disabled");
			0 == window.sliderBx.getCurrentSlide() && $(".slider-prev > a").addClass("disabled")
		},
		h = function() {
			$(".slider-prev > a").removeClass("disabled");
			Math.ceil(window.sliderBx.getSlideCount() / 3) - 1 == window.sliderBx.getCurrentSlide() && $(".slider-next > a").addClass("disabled")
		};
	$(".slider-prev > a").on("click", function() {
		window.sliderBx.goToPrevSlide();
		c()
	});
	$(".slider-next > a").on("click", function() {
		window.sliderBx.goToNextSlide();
		h()
	});
	$(".bx-prev").on("click", function() {
		c()
	});
	$(".bx-next").on("click", function() {
		h()
	})
};
var totop = {
	target: $(".to-top__fixed"),
	target2: $(".social-groups .to-top"),
	transform: function() {
		var c = $(window).width(),
			h = $(".social-groups"),
			h = h.offset().top + h.height() + 45,
			n = $(window).scrollTop() + $(window).height(),
			a = n > 2 * $(window).height();
		if (a && n < h) this.target.show(), this.target2.hide(), this.target.css("left", c / 2 + 525);
		else if (n >= h || !a) this.target.hide(), this.target2.show()
	},
	init: function() {
		this.transform();
		$(window).scroll(function() {
			totop.transform()
		});
		$(".to-top").on("click", function() {
			$("html, body").animate({
				scrollTop: 0
			}, 1E3)
		})
	}
},
	socIcon = {
		target: $(".soc-icons"),
		fixPoint: 1080,
		slide: function() {
			this.target.hover(function() {
				invalidId = setTimeout(function() {
					this.target.animate({
						right: "0"
					}, 600)
				}, 300)
			}, function() {
				clearInterval(invalidId);
				this.target.animate({
					right: "-125"
				}, 600)
			})
		},
		changePosition: function() {
			var c = 0 == $(".similar-machines .main-carousel").length ? $(".similar-machines").offset().top + 108 : $(".similar-machines .main-carousel").offset().top,
				h = $(window).scrollTop(),
				n = $(".soc-icons");
			h > c - 70 ? n.addClass("fixed") : n.removeClass("fixed")
		},
		init: function() {
			if ($(".soc-icons").length) {
				var c = this;
				$(window).scroll(function() {
					c.changePosition()
				})
			}
		}
	};
$(document).on("click", ".slots-list__btn", function(c) {
	c.preventDefault();
	var h = $(this),
		n = h.attr("href"),
		a = h.parents(".slots-list__items");
	h.addClass("slots-list__btn_loading");
	$.ajax({
		url: n
	}).always(function() {
		history.pushState("", document.title, n);
		h.removeClass("slots-list__btn_loading")
	}).done(function(c) {
		a.html(c);
		$(".machine-item__rating > input").rating({
			readOnly: !0
		})
	}).fail(function(c) {
		a.html('<div class="container">\u041e\u0448\u0438\u0431\u043a\u0430 ' + c.status + " " + c.statusText + "</div>")
	})
});
$(document).on("click", ".news-index .news__btn", function(c) {
	c.preventDefault();
	var h = $(this),
		n = h.attr("href"),
		a = h.closest('.facts');
	$.post('/ajax', {
		page: h.attr('data-page'),
		parent: h.attr('data-parent')
	}, function(c) {
		a.html(c)
	}).always(function() {
		history.pushState("", document.title, n);
	})
});
$(document).on("click", ".facts-index .news__btn", function(c) {
	c.preventDefault();
	var h = $(this),
		n = h.attr("href"),
		a = h.closest('.facts');
	$.post('/ajax', {
		page: h.attr('data-page'),
		parent: h.attr('data-parent')
	}, function(c) {
		a.html(c)
	}).always(function() {
		history.pushState("", document.title, n);
	})
});

function sameBlockSize(c, h) {
	var n = c.height();
	h.each(function() {
		$(this).css("height", n)
	})
}
$(document).on("click", ".game-iframe__btn-refresh", function(c) {
	c.preventDefault();
	c = document.getElementById("game-iframe");
	c.src = c.src
});
$(document).on("click", ".game-iframe__btn-full-screen", function(c) {
	c.preventDefault()
});
function scrollToElement(c, h) {
	h || (h = 700);
	return c.length ? ($("html, body").animate({
		scrollTop: c.offset().top
	}, h), !0) : !1
}
$(".scroll-to").on("click", function(c) {
	var h = $($(this).attr("data-target"));
	scrollToElement(h) && c.preventDefault()
});
var resizeBlock = {
	block: $("#game-iframe-container"),
	actionsPanel: jQuery(".game-iframe__btn-panel"),
	iframe: jQuery("#game-iframe"),
	init: function() {
		$(document).on("click", ".game-iframe__btn-full-screen", function(c) {
			c.preventDefault();
			resizeBlock.fullScreen($(this))
		});
		$(document).on("click", ".game-iframe__btn-normal-screen", function(c) {
			c.preventDefault();
			resizeBlock.normalScreen($(this))
		})
	},
	fullScreen: function(c) {
		var h = resizeBlock.actionsPanel,
			n = $(window).width(),
			a = $(window).height(),
			d = resizeBlock.block;
		$(".header").css("z-index", 0);
		$(".footer").css("z-index", 0);
		$(".wrap-soc-icons").css("z-index", 0);
		$(".to-top__fixed").css("z-index", 0);
		$(".bx-wrapper .bx-controls-direction a").css("z-index", 0);
		d.fadeOut(200, function() {
			d.addClass("game-iframe_fixed").css("width", n).css("height", a);
			h.addClass("game-iframe__btn-panel_fixed");
			c.removeClass("game-iframe__btn-full-screen").addClass("game-iframe__btn-normal-screen");
			c.children(".glyphicon").removeClass("glyphicon-fullscreen").addClass("glyphicon-normalscreen");
			d.fadeIn(200)
		})
	},
	normalScreen: function(c) {
		var h = resizeBlock.actionsPanel,
			n = resizeBlock.block;
		$(".header").removeAttr("style");
		$(".footer").removeAttr("style");
		$(".wrap-soc-icons").removeAttr("style");
		$(".to-top__fixed").removeAttr("style");
		$(".bx-wrapper .bx-controls-direction a").removeAttr("style");
		n.fadeOut(200, function() {
			n.removeClass("game-iframe_fixed").removeAttr("style");
			h.removeClass("game-iframe__btn-panel_fixed");
			c.removeClass("game-iframe__btn-normal-screen").addClass("game-iframe__btn-full-screen");
			c.children(".glyphicon").removeClass("glyphicon-normalscreen").addClass("glyphicon-fullscreen");
			n.fadeIn(200);
			scrollToElement(n)
		})
	}
},
	socInteractive = {
		init: function() {
			jQuery(document).on("mouseenter", ".soc-interactive", function() {
				socInteractive.run()
			})
		},
		run: function() {
			jQuery(".soc-interactive").removeClass("soc-interactive");
			createScript("pinterest-pinit", "//assets.pinterest.com/js/pinit.js", !1, !0);
			createScript("twitter-wjs", "http://platform.twitter.com/widgets.js", !1, !0);
			createScript("facebook-init", !1, 'window.fbAsyncInit = function() {FB.init({appId      : "364826410334739",xfbml      : true,version    : "v2.0"});};', !0);
			$(".fb-share-button, .fb-like, .fb-follow").html("");
			createScript("facebook-jssdk", "http://connect.facebook.net/ru_RU/sdk.js", !1, !0);
			createScript("google-plus-platform", "https://apis.google.com/js/platform.js", "{lang: 'ru', parsetags: 'explicit'}", !0);
			jQuery("#google-plus-platform").load(function() {
				gapi.plusone.go();
				gapi.plus.go();
				gapi.ytsubscribe.go("ytSubscribeButton")
			});
			createScript("vk-widgets", "http://vk.com/js/api/openapi.js?101", !1, !0);
			jQuery("#vk-widgets").load(function() {
				VK.init({
					apiId: 4528577,
					onlyWidgets: !0
				});
				VK.Widgets.Like("vk-like-right", {
					type: "mini"
				});
				jQuery('#vk-like-right span[class^="soc-icon-"]').remove();
				VK.Widgets.Like("vk-like-top", {
					type: "button"
				});
				jQuery('#vk-like-top span[class^="soc-icon-"]').remove();
				VK.Widgets.Subscribe("vk-subscribe", {
					mode: 1,
					soft: 1
				}, -74253374);
				jQuery('#vk-subscribe span[class^="soc-icon-"]').remove()
			})
		}
	};

function createScript(c, h, n, a) {
	var d, f = document.getElementsByTagName("head")[0];
	return document.getElementById(c) ? !1 : (d = document.createElement("script"), d.setAttribute("type", "text/javascript"), d.setAttribute("id", c), d.setAttribute("data-load", "false"), h && d.setAttribute("src", h), n && (d.innerHTML = n), a && d.setAttribute("async", ""), f.appendChild(d), !0)
}

function createStyle(c, h) {
	var n, a = document.getElementsByTagName("head")[0];
	document.getElementById(c) || (n = document.createElement("style"), n.setAttribute("id", c), n.innerHTML = h, a.appendChild(n))
}
$(document).ready(function() {
	//showTooltip();
	sliderFn()
});
$(window).load(function() {
	vmiddleObject($(".blocks .block"), $(".block figure img"))
});
$(document).ready(function() {
	$("#subscribe-form").on("submit", function(c) {
		c.preventDefault();
		c = $("#subscribe-form");
		c.css("border-color", "#cccccc");
		$(".incorrect-email-message").hide();
		subscribe(c.find(".form-control").val()).done(function(c) {
			c.success ? showModalSuccess() : ($("#subscribe-form").css("border-color", "red"), $(".incorrect-email-message").text(c.message).show())
		})
	});
	$("#news-subscribe-form").on("submit", function(c) {
		c.preventDefault();
		var h = $("#news-subscribe-form");
		$(".subscribe-error-message").hide();
		subscribe(h.find(".form-control").val()).done(function(c) {
			c.success ? showModalSuccess() : h.find(".form-control").after('<p class="subscribe-error-message">' + c.message + "</p>")
		})
	})
});
function subscribe(c) {
	return $.ajax({
		url: "/ajax-request/subscribe",
		type: "POST",
		data: {
			email: c
		}
	})
}

function showModalSuccess() {
	var c = $("#subscribe-msg");
	c.find(".modal-body").text("\u041d\u0430 \u0432\u0430\u0448 \u043f\u043e\u0447\u0442\u043e\u0432\u044b\u0439 \u044f\u0449\u0438\u043a \u0431\u044b\u043b\u043e \u0432\u044b\u0441\u043b\u0430\u043d\u043e \u043f\u0438\u0441\u044c\u043c\u043e \u0434\u043b\u044f \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043d\u0438\u044f \u043f\u043e\u0434\u043f\u0438\u0441\u043a\u0438");
	c.modal("show")
}

function unsubscribe(c) {
	$("#unsubscribe-form").modal("show");
	$(document).on("click", "#unsubscribe-form .btn-ok", function() {
		$.ajax({
			url: "/ajax-request/unsubscribe",
			type: "POST",
			data: {
				code: c
			}
		}).done(function(c) {
			$("#unsubscribe-form").modal("hide");
			var n = $("#subscribe-msg");
			n.find(".modal-body").text(c.message);
			n.modal("show")
		})
	})
}
var feedbackForm = {
	loaded: !1,
	load: function() {
		$.ajax({
			url: "/ajax-request/get-feedback-modal"
		}).done(function(c) {
			$("body").append(c);
			feedbackForm.loaded = !0;
			feedbackForm.run();
			$("#feedback-form").on("afterValidate", function(c, n) {
				var a = c.currentTarget;
				n.robot && ($(a).find(".feedback-form-error-message").remove(), $(a).prepend('<div class="feedback-form-error-message text-danger">' + n.robot + "</div>"));
				return !0
			})
		})
	},
	init: function() {
		$(document).ready(function() {
			$(document).on("click", "#feedback-btn", function() {
				feedbackForm.run()
			});
			$(document).on("hidden.bs.modal", "#feedback-modal", function() {
				$(this).remove();
				feedbackForm.loaded = !1
			});
			$(document).on("submit", "#feedback-form", function(c) {
				c.preventDefault();
				$.ajax({
					url: "/ajax-request/feedback-form-send",
					type: "POST",
					data: $("#feedback-form").serialize()
				}).success(function(c) {
					$(".feedback-modal-form").html(c.form);
					c.send && setTimeout(function() {
						$("#feedback-modal").modal("hide")
					}, 3E3)
				})
			})
		})
	},
	run: function() {
		feedbackForm.loaded ? $("#feedback-modal").modal() : feedbackForm.load()
	}
};
$(document).ready(function() {
	$(document).on("click", ".fake-link", function(c) {
		c.preventDefault();
		var h = $(this);
		c = h.attr("data-link");
		h = h.attr("data-link-target");
		if (void 0 === c) return !1;
        window.location.href = c;

		//h && "blank" === h ? window.open(c, "_blank") : window.location.href = c
	})
});
var authForm = {
	loaded: !1,
	load: function() {
		$.ajax({
			url: "/ajax-request/get-auth-form"
		}).done(function(c) {
			$("body").append(c);
			authForm.loaded = !0;
			authForm.run()
		})
	},
	init: function() {
		$(document).on("click", ".get-auth-form", function() {
			authForm.run()
		})
	},
	run: function() {
		authForm.loaded ? $("#auth-form").modal() : authForm.load()
	}
};
function getCookie(c) {
	return (c = document.cookie.match(new RegExp("(?:^|; )" + c.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"))) ? decodeURIComponent(c[1]) : void 0
}

function setCookie(c, h, n) {
	n = n || {};
	var a = n.expires;
	if ("number" == typeof a && a) {
		var d = new Date;
		d.setTime(d.getTime() + 1E3 * a);
		a = n.expires = d
	}
	a && a.toUTCString && (n.expires = a.toUTCString());
	h = encodeURIComponent(h);
	c = c + "=" + h;
	for (var f in n) c += "; " + f, h = n[f], !0 !== h && (c += "=" + h);
	document.cookie = c
}
function deleteCookie(c) {
	setCookie(c, "", {
		expires: -1
	})
};